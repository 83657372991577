import React from 'react'
import { graphql, useStaticQuery, ContentfulBlogPostEdge } from 'gatsby'
import { chunk } from 'lodash-es'
import styled from '@emotion/styled'
import Fade from 'react-reveal/Fade'

import Theme, { Mq } from '../themes/default'
import { SubpageHero } from '../molecules/subpage-hero'
import { Row, Column } from '../atoms/grid'
import { FooterHero } from '../molecules/footer-hero'
import { BlogCard } from '../molecules/blog-card'
import Layout from '../organisms/layout'
import SEO from '../organisms/seo'
import { IContentfulBlogPostMin } from '../interfaces'

const BlogIndexContainer = styled.div`
  padding: 0 5% 7%;

  ${Mq.md} {
    padding: 0 3%;
    margin: 0 5% 7%;
  }
  ${Mq.xxl} {
    margin: 0 15% 7%;
  }
`

const Blog = () => {
  const { allContentfulBlogPost } = useStaticQuery(
    graphql`
      query BlogIndexQuery {
        allContentfulBlogPost(sort: { fields: publishDate, order: DESC }) {
          edges {
            node {
              title
              slug
              publishDate(formatString: "MMMM Do, YYYY")
              author {
                name
              }
              featuredMedia {
                fluid(maxWidth: 500) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
              seoDescription {
                seoDescription
              }
            }
          }
        }
      }
    `,
  )

  const chunkedEdges = chunk(allContentfulBlogPost.edges, 2)
  return (
    <Layout>
      <SEO
        description="Understand how bias impacts your company and learn more about BiasSync and our insight on how to assess and manage unconscious bias in the workplace."
        title="Unconscious Bias & Workplace Discrimination Resources | BiasSync"
      />
      <SubpageHero title="BiasSync Blog" text="Our insights about unconscious bias in the workplace." />
      <BlogIndexContainer>
        {chunkedEdges.map(
          (edgeGroup: ContentfulBlogPostEdge[], index: number) => {
            return (
              <Row flexWrap="wrap" key={index} my={[3, 4]}>
                {edgeGroup.map((edge: ContentfulBlogPostEdge) => {
                  const blogPost = edge.node as IContentfulBlogPostMin

                  return (
                    <Column
                      key={blogPost.slug}
                      mb={[4, 0, 0]}
                      width={[1, 1 / 2, 1 / 2]}
                    >
                      <Fade bottom={true} distance={Theme.fadeDistance}>
                        <BlogCard blogPost={blogPost} />
                      </Fade>
                    </Column>
                  )
                })}
              </Row>
            )
          },
        )}
      </BlogIndexContainer>

      <FooterHero />
    </Layout>
  )
}

export default Blog
